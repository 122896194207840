var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-row',{attrs:{"justify":"center"}},[_c('material-card',{attrs:{"width":"1500px","icon":"mdi-cellphone-dock","color":"primary","title":_vm.$t(_vm.entity + '.TITLE'),"text":_vm.$t(_vm.entity + '.SUBTITLE')}},[_c('v-data-table',{attrs:{"no-results-text":"No se encontraron resultados","search":_vm.search,"hide-default-footer":"","headers":_vm.headers,"items":_vm.items,"sort-by":"calories","page":_vm.page,"items-per-page":_vm.$store.state.itemsPerPage},on:{"page-count":function($event){_vm.pageCount = $event},"update:page":function($event){_vm.page=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-container',[_c('span',{staticClass:"font-weight-bold"},[_vm._v("Filtrar por nombre: "+_vm._s(_vm.search))]),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',{attrs:{"dense":"","hide-details":"","append-icon":"search","placeholder":"Escribe el nombre de la marca","single-line":"","outlined":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-dialog',{attrs:{"max-width":"700px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({directives:[{name:"show",rawName:"v-show",value:(_vm.rolPermisos['Write']),expression:"rolPermisos['Write']"}],staticClass:"mb-2",attrs:{"color":"primary","dark":""}},on),[_vm._v(_vm._s(_vm.$t(_vm.entity + ".NEW_ITEM")))])]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-card-title',[_c('v-icon',{staticClass:"mr-1",attrs:{"color":"primary"}},[_vm._v("mdi-update")]),_c('span',{staticClass:"headline"},[_vm._v(_vm._s(_vm.formTitle))])],1),_c('v-divider'),_c('ValidationObserver',{ref:"obs",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var passes = ref.passes;
return [_c('v-container',{staticClass:"pa-5"},[_c('v-row',{attrs:{"dense":""}},[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('p',{staticClass:"body-1 font-weight-bold"},[_vm._v("Nombre")]),_c('VTextFieldWithValidation',{attrs:{"rules":"required","label":"Nombre de la categoría"},model:{value:(_vm.editedItem.name),callback:function ($$v) {_vm.$set(_vm.editedItem, "name", $$v)},expression:"editedItem.name"}})],1)],1)],1),_c('v-card-actions',{attrs:{"rd-actions":""}},[_c('div',{staticClass:"flex-grow-1"}),_c('v-btn',{attrs:{"outlined":"","color":"error","text":""},on:{"click":_vm.close}},[_vm._v("Cancelar")]),_c('v-btn',{attrs:{"loading":_vm.loadingButton,"color":"success"},on:{"click":function($event){return passes(_vm.save)}}},[_vm._v("Guardar")])],1)]}}])})],1)],1)],1)],1)],1)]},proxy:true},{key:"item.action",fn:function(ref){
var item = ref.item;
return [(_vm.rolPermisos['Edit'])?_c('v-btn',{staticClass:"mr-1 mb-1",attrs:{"color":"primary","fab":"","small":"","dark":""},on:{"click":function($event){return _vm.editItem(item)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1):_vm._e(),(_vm.rolPermisos['Delete'])?_c('v-btn',{attrs:{"color":"error","fab":"","small":"","dark":""},on:{"click":function($event){return _vm.deleteItem(item)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1):_vm._e()]}},{key:"no-data",fn:function(){return [_c('v-alert',{attrs:{"type":"error","value":true}},[_vm._v(_vm._s(_vm.$t("users.NO_DATA")))])]},proxy:true},{key:"item.description",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"format-breaklines"},[_vm._v(" "+_vm._s(item.description)+" ")])]}},{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(_vm._s(_vm._f("formatDate")(item.createdAt)))]}},{key:"item.status",fn:function(ref){
var item = ref.item;
return [(item.status)?_c('v-chip',{attrs:{"color":"success"}},[_vm._v("Activo")]):_c('v-chip',{attrs:{"color":"error"}},[_vm._v("Inactivo")])]}}],null,true)}),_c('v-col',{attrs:{"cols":"12","sm":"12"}},[_c('span',[_c('strong',[_vm._v("Mostrando:")]),_vm._v(" "+_vm._s(_vm.$store.state.itemsPerPage > _vm.items.length ? _vm.items.length : _vm.$store.state.itemsPerPage)+" de "+_vm._s(_vm.items.length)+" registros ")])]),_c('div',{staticClass:"text-center pt-2"},[_c('v-pagination',{attrs:{"length":_vm.pageCount},model:{value:(_vm.page),callback:function ($$v) {_vm.page=$$v},expression:"page"}})],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }